import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export type Tlevel = {
	id: number;
	label: string;
	value: string;
	is_dropdown?: boolean;
};
interface TData {
	levels: Tlevel[];
	addLevel: () => void;
	last_level_id: number;
	set_level_value: (id: number, value: string) => void;
	set_levels: (count: number) => void;
	//hierarch_rows_count: number
}

// const initialState: Tlevel[] = [
// 	{ id: 1, label: 'Level 1', value: 'Non-VioScore™' },
// 	{ id: 2, label: 'Level 2', value: 'UNDEFINED', is_dropdown: true },
// 	{ id: 3, label: 'Level 3', value: 'UNDEFINED' },
// 	{ id: 4, label: 'Level 4', value: 'NULL' },
// ];
const useHierarchyDefinitionStore = create<TData>()(
	immer(set => ({
		levels: [],

		last_level_id: 0,
		addLevel: () => {
			set(state => {
				state.levels = [
					...state.levels,
					{
						id: state.last_level_id + 1,
						label: `Label ${state.last_level_id + 1}`,
						value: 'NULL',
					},
				];
				state.last_level_id++;
			});
		},
		set_levels: count => {
			set(state => {
				const newVals = Array.from({ length: count - 1 }, (_, idx) => {
					return {
						id: idx + 1,
						label: `Level ${idx + 1}`,
						value:
							idx == 0
								? 'Non-VioScore™'
								: idx == 1 || idx == 2
								  ? 'Undefined'
								  : 'NULL',
						is_dropdown: idx == 1 ? true : undefined,
					};
				});
				state.levels = newVals;
				state.last_level_id = newVals.length;
			});
		},

		set_level_value: (id, value) => {
			set(state => {
				state.levels[id].value = value;
			});
		},
	})),
);

export default useHierarchyDefinitionStore;
