import { isValid } from 'date-fns';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export interface ITableData {
	column_name: string;
	data_type: unknown;
	nullable: string;
	unique: string;
	default: string;
	pkey: string;
	identity: string;
	is_json: string;
	//pkey_identity: string;
	//pkey_unique: string;
}
interface TImportStoreData {
	file: File | null;
	added_rows_columns: { id: number; level: number; label: string }[];
	inserted_ids_in_model: number[];
	set_inserted_ids_in_model: (vals: number[]) => void;
	set_added_rows: (
		items: { id: number; level: number; label: string }[],
	) => void;
	table_name: string;
	headers: string[];
	table_rows: Record<string, unknown>[];
	set_table_rows: (rows: Record<string, unknown>[]) => void;
	rows: unknown[];
	setStoreFile: (file: File) => void;
	setHeaders: (headers: string[]) => void;
	setRows: (rows: unknown[]) => void;
	dynamic_table_data: ITableData[];
	set_table_data: (data: ITableData[]) => void;
	reset_table_data_value: (
		id: number,
		key: keyof ITableData,
		value: string,
	) => void;
	setTableName: (name: string) => void;
}

const useDataImportStore = create<TImportStoreData>()(
	immer(set => ({
		added_rows_columns: [],
		inserted_ids_in_model: [],
		file: null,
		headers: [],
		table_name: '',
		table_rows: [],
		rows: [],
		setStoreFile: file => {
			set(state => {
				state.file = file;
			});
		},
		setHeaders: headers => {
			set(state => {
				state.headers = headers;
				//let rows = state.rows;
				const default_table_state = state.rows.map((el, idx) => {
					//console.log(typeof el);
					//console.log(isValid(new Date(null)))
					return {
						column_name: state.headers[idx],
						data_type:
							typeof el === 'number'
								? 'integer'
								: typeof el === 'string' &&
								    isValid(new Date(el as string))
								  ? 'date'
								  : 'text',
						nullable: 'NULL',
						unique: 'null',
						pkey: 'null',
						identity: 'null',
						is_json: 'null',
						pkey_identity: 'null',
						pkey_unique: 'null',
						default: 'NOT NULL',
					};
				});
				//console.log(state.headers);
				state.dynamic_table_data = default_table_state;
			});
		},
		setRows: rows => {
			set(state => {
				state.rows = rows;
			});
		},
		dynamic_table_data: [],
		set_table_data: data => {
			set(state => {
				state.dynamic_table_data = data;
			});
		},
		reset_table_data_value(id, key, value) {
			set(state => {
				state.dynamic_table_data[id][key] = value;
			});
		},
		setTableName(name) {
			set(state => {
				state.table_name = name;
			});
		},
		set_table_rows(rows) {
			set(state => {
				state.table_rows = rows;
			});
		},
		set_added_rows(items) {
			set(state => {
				state.added_rows_columns = items;
			});
		},
		set_inserted_ids_in_model(vals) {
			set(state => {
				state.inserted_ids_in_model = vals;
			});
		},
	})),
);

export default useDataImportStore;
