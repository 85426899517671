import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '../components/ui/card';
import { Label } from '../components/ui/label';
import { Input } from '../components/form/textField';
import { Button } from '../components/ui/button';
import useHierarchyDefinitionStore from '../stores/hierarchyDefinitionStore';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '../components/ui/select';
import clsx from 'clsx';
import { Separator } from '../components/ui/separator';
import ColumnsView from '../components/data-manager/columns-view';
import { useLoaderData, useNavigate } from 'react-router-dom';
import useDataImportStore from '../stores/dataImportStore';
import {
	getPreviewData,
	updateHierarchyTableAndModel,
} from '../requests/services/data-import/hook';
import { Info, Loader2 } from 'lucide-react';
import IntelligencePreviewModal from '../components/intelligence/intelligencePreviewModal';
import { stat } from 'fs';
import arrToObject from '../utils/arrToObject';
import useLoadigStatusStore from '../stores/loadingStatusStore';
import { z } from 'zod';

const DataManagerHierarchyPage = () => {
	const rows = useDataImportStore(state => state.dynamic_table_data);

	const [previewOpen, setPreviewOpen] = React.useState(false);

	const navigate = useNavigate();
	const hierarchyData = useLoaderData() as any[];
	const {
		isLoading,
		isFetched,
		isError,
		refetch: saveHierarchy,
	} = updateHierarchyTableAndModel(hierarchyData.length);
	const [nextSteps, setNextSteps] = useState(false);
	const levels = useHierarchyDefinitionStore(state => state.levels);
	const headers = useDataImportStore(state => state.headers);
	const insertLevel = useHierarchyDefinitionStore(state => state.addLevel);
	const setValue = useHierarchyDefinitionStore(
		state => state.set_level_value,
	);
	const setTableName = useDataImportStore(state => state.setTableName);
	const table_name = useDataImportStore(state => state.table_name);
	const resetStatusStore = useLoadigStatusStore(state => state.reset_store);
	useEffect(() => {
		resetStatusStore();
		rows.length == 0 && navigate('/intelligence/data-import');
	}, []);

	const openPreview = () => {
		setPreviewOpen(true);
	};

	const closePreview = () => {
		setPreviewOpen(false);
	};
	const { data: lineageData } = getPreviewData({
		headers,
		parsed_data: arrToObject(levels),
		modalOpen: previewOpen,
	});

	const table_name_schema = z.string().regex(/^[a-zA-Z0-9_]*$/, {
		message: 'The provided table name is invalid',
	});

	const [tableNameSuccess, setIsTableNameSuccess] = useState<
		boolean | undefined
	>();
	return (
		<div className='py-7 min-w-fit flex flex-shrink-0'>
			<div className=' px-8 flex items-center  justify-center w-full'>
				<div className='bg-mlk-blue border flex flex-col rounded-md  border-gray-600 w-full'>
					<div className='w-full flex flex-1 min-w-fit'>
						<div className='w-[312px] border-r flex-shrink-0 border-r-[#313648] min-h-full flex justify-start flex-col px-5 pt-5 gap-2'>
							<p className='mb-4 font-grotesk font-semibold text-[20px] leading-[24.69px]'>
								Next Steps
							</p>

							<ol className='list-decimal mb-[200px] list-outside font-grotesk font-normal text-[12px] leading-4 pl-4'>
								<li>
									Define the hierarchy to be used in
									Intelligence. Your table headers will appear
									as leaf nodes at the end of the branch.
									Levels with NULL values will not be
									displayed.
								</li>
								<div className='space-y-4 w-full mb-6'>
									{levels.map((level, idx) => {
										return (
											<div key={level.id}>
												<Label
													htmlFor='firstname'
													className='text-right text-[#E0E0E0] font-roboto font-semibold text-[12px] leading-7'
												>
													{level.label}
												</Label>
												{level.is_dropdown ? (
													<>
														<Select
															onValueChange={e =>
																setValue(idx, e)
															}
															defaultValue={
																level.value
															}
														>
															<SelectTrigger className='w-[251px] text-[12px] text-[#E0E0E0] font-semibold font-roboto leading-7 h-9 px-2 focus:border-white/10 focus:ring-white/10 bg-white/10  border border-white/10'>
																<SelectValue placeholder='UNDEFINED' />
															</SelectTrigger>
															<SelectContent
																className='bg-white text-black'
																onCloseAutoFocus={e =>
																	e.preventDefault()
																}
															>
																<SelectItem value='Health'>
																	Health
																</SelectItem>
																<SelectItem value='Credit'>
																	Credit
																</SelectItem>
																<SelectItem value='Undefined'>
																	UNDEFINED
																</SelectItem>
																<SelectItem value='Environment'>
																	Environment
																</SelectItem>
																<SelectItem value='World'>
																	World
																</SelectItem>
															</SelectContent>
														</Select>
														<Separator
															className='border border-[#313648] w-full mt-3'
															orientation='horizontal'
														/>
													</>
												) : (
													<Input
														readOnly={level.id == 1}
														//id='username'
														onChange={e =>
															setValue(
																idx,
																e.target.value,
															)
														}
														defaultValue={
															level.value
														}
														className={clsx(
															'w-[251px]',
															level.id == 1 ||
																level.value ==
																	'NULL'
																? 'text-[#E0E0E0]/50'
																: 'text - [#E0E0E0]',
															'text-sm font-semibold  text-[12px] font-roboto leading-7 h-9 px-2 focus:border-white/10 focus:ring-white/10 bg-white/10  border border-white/10',
														)}
													/>
												)}
											</div>
										);
									})}

									<Button
										type='button'
										onClick={insertLevel}
										variant='outline'
										className='border-mlk-light-blue h-9 text-mlk-light-blue hover:bg-mlk-light-blue hover:text-white w-full rounded-[4px] font-grotesk text-[12px] font-bold leading-[14.81px]'
									>
										INSERT NEW LEVEL
									</Button>
									<div className='flex gap-2 items-center w-full'>
										{/* <PreviewModal /> */}
										<Button
											onClick={() => openPreview()}
											variant='outline'
											className='disabled:bg-mlk-light-blue/95 disabled:text-white border-mlk-light-blue w-[121px] h-9 text-mlk-light-blue hover:bg-mlk-light-blue hover:text-white  rounded-[4px] font-grotesk text-[12px] font-bold leading-[14.81px]'
										>
											PREVIEW
										</Button>
										<Button
											//onClick={() => refetch()}
											disabled={!!isFetched}
											onClick={() => {
												if (
													levels.length >=
													hierarchyData.length
												) {
													//perform the table functions
													saveHierarchy();
												}
												setNextSteps(true);
											}}
											variant='outline'
											className='disabled:bg-mlk-light-blue/95 disabled:text-white border-mlk-light-blue w-[121px] h-9 text-mlk-light-blue hover:bg-mlk-light-blue hover:text-white  rounded-[4px] font-grotesk text-[12px] font-bold leading-[14.81px]'
										>
											{isLoading ? (
												<>
													<Loader2 className='mr-2 h-4 w-4 animate-spin' />
													Saving..
												</>
											) : (
												'SAVE'
											)}
										</Button>
									</div>
									{isError && (
										<p className='text-xs text-red-600'>
											Process Failed.. Please try again.!
										</p>
									)}
								</div>
								{nextSteps && (
									<div className='flex flex-col gap-4'>
										<Separator
											className='border border-[#313648] w-full'
											orientation='horizontal'
										/>
										<li>
											Name the table which this data will
											be imported into:
										</li>
										<div>
											<Input
												onChange={e =>
													setTableName(e.target.value)
												}
												defaultValue={table_name}
												className={clsx(
													'w-[251px] text-[#E0E0E0]/50 text - [#E0E0E0] text-sm font-semibold  text-[12px] font-roboto leading-7 h-9 px-2 focus:border-white/10 focus:ring-white/10 bg-white/10  border border-white/10',
												)}
											/>
											{tableNameSuccess == false && (
												<div className='flex flex-row gap-1 items-center mt-1'>
													<Info className='w-4 h-4 text-red-600' />
													<p className='text-[10px] text-red-600 mt-1'>
														Please only use
														alphanumeric and
														underscores
													</p>
												</div>
											)}
										</div>

										<Separator
											className='border border-[#313648] w-full'
											orientation='horizontal'
										/>
										<li>
											Confirm the Column structure is
											correct, and select one or more
											primary keys.
										</li>
										<Separator
											className='border border-[#313648] w-full'
											orientation='horizontal'
										/>
										<div className='flex gap-2 items-center w-full'>
											<Button
												//	onClick={() => getPreviewData()}
												variant='outline'
												className='border-mlk-light-blue w-[121px] h-9 text-mlk-light-blue hover:bg-mlk-light-blue hover:text-white rounded-[4px] font-grotesk text-[12px] font-bold leading-[14.81px]'
											>
												CANCEL
											</Button>
											<Button
												onClick={async () => {
													const res =
														await table_name_schema.safeParseAsync(
															table_name,
														);

													setIsTableNameSuccess(
														res.success,
													);
													if (res.success) {
														resetStatusStore();
														///validate table name input
														navigate(
															'/intelligence/data-import/loading',
														);
													}
												}}
												variant='outline'
												className='border-mlk-light-blue w-[121px] h-9 text-mlk-light-blue hover:bg-mlk-light-blue hover:text-white  rounded-[4px] font-grotesk text-[12px] font-bold leading-[14.81px]'
											>
												IMPORT
											</Button>
										</div>
									</div>
								)}
							</ol>
							{/*  mb-[123px] */}
						</div>
						{nextSteps && <ColumnsView />}
					</div>
				</div>
			</div>
			{previewOpen && (
				<IntelligencePreviewModal
					closePreview={closePreview}
					lineageData={lineageData}
				/>
			)}
		</div>
	);
};

export default DataManagerHierarchyPage;
