import React, { useEffect } from 'react';
import { Card, CardContent, CardHeader } from '../components/ui/card';
import useDataImportStore from '../stores/dataImportStore';
import { useNavigate } from 'react-router-dom';
import useLoadigStatusStore from '../stores/loadingStatusStore';
import { Button } from '../components/ui/button';
import useHierarchyDefinitionStore from '../stores/hierarchyDefinitionStore';
import CreateTable from '../components/data-manager/create-table';
import CreateColumns from '../components/data-manager/create-columns';
import CreateRows from '../components/data-manager/create-rows';
import AddToIntelligence from '../components/data-manager/add-to-intelligence';
import ImportToStorage from '../components/data-manager/import-to-storage';

const UploadStatusPage = () => {
	const navigate = useNavigate();
	const setBeginProcess = useLoadigStatusStore(state => state.start_process);
	const file_name = useDataImportStore(state => state.file);

	useEffect(() => {
		!file_name && navigate('/intelligence/data-import');
		setBeginProcess(true);
	}, []);

	const isImportedToStorage = useLoadigStatusStore(
		state => state.is_imported_to_storage,
	);
	return (
		<div className='w-full h-screen flex items-center justify-center'>
			<Card className='w-[620px] rounded-[4px] border-[#313648] bg-mlk-blue '>
				<CardHeader className='font-grotesk text-[20px] font-semibold leading-[24.69px]'>
					{`Importing  ${file_name?.name}`}
				</CardHeader>
				<CardContent>
					<div className='flex flex-col space-y-4'>
						<CreateTable />
						<CreateColumns />
						<CreateRows />
						<AddToIntelligence />
						<ImportToStorage />
						{isImportedToStorage && (
							<div className='flex flex-row gap-2 items-center w-full'>
								<Button
									onClick={() => navigate('/intelligence')}
									variant='outline'
									className='border-mlk-light-blue w-[230px] h-9 text-mlk-light-blue hover:bg-mlk-light-blue hover:text-white rounded-[4px] font-grotesk text-[12px] font-bold leading-[14.81px]'
								>
									VIEW IN INTELLIGENCE
								</Button>
								<Button
									onClick={() => {
										// reset all stores
										useLoadigStatusStore.setState({}, true);
										useHierarchyDefinitionStore.setState(
											{},
											true,
										);
										useDataImportStore.setState({}, true);
										navigate('/intelligence/data-import');
									}}
									variant='outline'
									className='border-mlk-light-blue w-[121px] h-9 text-mlk-light-blue hover:bg-mlk-light-blue hover:text-white  rounded-[4px] font-grotesk text-[12px] font-bold leading-[14.81px]'
								>
									DONE
								</Button>
							</div>
						)}
					</div>
				</CardContent>
			</Card>
		</div>
	);
};

export default UploadStatusPage;
